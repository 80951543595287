import Title from 'components/landing/Title';
import TeamCard from 'components/landing/TeamCard';
import Image1 from 'assets/img/try.jpeg';
import Image2 from 'assets/img/administration.jpeg';
import Image3 from 'assets/img/hero.jpeg';
// import Image4 from 'assets/img/Bibini.png';
import Image4 from 'assets/img/Kojo.jpeg';
import Image5 from 'assets/img/Naa_Shormeh.jpeg';
import Image6 from 'assets/img/Bibini.png';
import Image7 from 'assets/img/coo_reform.png';
import Image8 from 'assets/img/Kwame.png';

export default function TeamSection() {
    return (
        <section className="pt-20 pb-48">
            <div className="container max-w-7xl mx-auto px-4">
                <Title heading="Here are our heroes">
                    These are the mighty people that work together to bring you the awesome products that you love.
                </Title>
                <div className="flex flex-wrap">
                    <TeamCard
                        img={Image1}
                        name="Nana Obiri"
                        position="Quality Control"
                    />
                    <TeamCard
                        img={Image2}
                        name="Adwoa"
                        position="Administration"
                    />
                    <TeamCard
                        img={Image3}
                        name="Dzidzor"
                        position="Customer Satisfaction"
                    />
                    <TeamCard
                        img={Image4}
                        name="Kojo"
                        position="UI/UX Designer"
                    />
                    <TeamCard
                        img={Image5}
                        name="Naa Shormeh"
                        position="Frontend Engineer"
                    />
                     <TeamCard
                        img={Image6}
                        name="Bibini"
                        position="Director"
                    />
                    <TeamCard
                        img={Image7}
                        name="Nana Yaa"
                        position="COO"
                    />
                    <TeamCard
                        img={Image8}
                        name="Kwame"
                        position="Lead Engineer and CEO"
                    />
                </div>
            </div>
        </section>
    );
}
