import Title from 'components/landing/Title';
import ContactCard from 'components/landing/ContactCard';
import Form from 'components/landing/Form';

export default function ContactSection() {
    return (
        <section className="pb-20 relative block bg-gray-100">
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
                <Title heading="Let's build something awesome together!">
                    Got it all thought of? Or just have the "hitch"? Hit us up and let's work together to bring that awesome idea to life.
                </Title>

                <div className="flex flex-wrap -mt-12 justify-center">
                    <ContactCard icon="stars" title="Excellent Services">
                        {/* Some quick example text to build on the card title and
                        make up the bulk of the card's content. */}
                    </ContactCard>
                    <ContactCard icon="launch" title="Unparalleled Launch Time">
                        {/* Some quick example text to build on the card title and
                        make up the bulk of the card's content. */}
                    </ContactCard>
                    <ContactCard icon="insert_chart" title="Grow Your Market">
                        {/* Some quick example text to build on the card title and
                        make up the bulk of the card's content. */}
                    </ContactCard>
                    
                </div>

                <Form />
            </div>
        </section>
    );
}
