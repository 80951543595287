import H2 from '@material-tailwind/react/Heading2';
import LeadText from '@material-tailwind/react/LeadText';

export default function Header() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                        <H2 color="white">Your dreams are actualized here.</H2>
                        <div className="text-gray-200">
                            <LeadText color="gray-200">
                                We are an experienced, dynamic and effective team,
                                thrilled to provide transformative solutions to its clients.
                            </LeadText>
                            {/* <LeadText color="gray-50">
                                We differ not only by having the technical-know-how to build sophisticated software solutions, 
                                but also as as established user-experience powerhouse specializing in maximizing user productivity. 
                            </LeadText> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
